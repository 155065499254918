import { capitalize } from '../../../helpers';
import { COLOR } from '../styler.definition';
const COLOR_STYLESHEETS = {};
Object.values(COLOR).forEach((value) => {
    const nameSpace = capitalize(value);
    COLOR_STYLESHEETS[`bc${nameSpace}`] = { borderColor: `$color${nameSpace}` };
    COLOR_STYLESHEETS[`bg${nameSpace}`] = { backgroundColor: `$color${nameSpace}` };
    COLOR_STYLESHEETS[`color${nameSpace}`] = { color: `$color${nameSpace}` };
});
export { COLOR_STYLESHEETS };
