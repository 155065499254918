var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Platform, Pressable } from 'react-native';
import { testID } from '../../../helpers';
import { 
// Helpers
ALIGN, POINTER, 
// Hooks
styles, useBanStylerProps, useDevice, useKeyboard, useStyler, } from '../../../hooks';
import { PRESSABLE_OPACITY, ROLES } from './Touchable.definition';
import { style } from './Touchable.style';
const Touchable = (_a) => {
    var { children, role = ROLES.button, onPress, pressableOpacity = PRESSABLE_OPACITY.DEFAULT } = _a, others = __rest(_a, ["children", "role", "onPress", "pressableOpacity"]);
    const { isKeyboardOpen, closeKeyboard } = useKeyboard();
    const handlePress = (event) => {
        Platform.OS !== 'web' && isKeyboardOpen && closeKeyboard();
        onPress && onPress(event);
    };
    const { style: styler } = Object.assign({}, useStyler(Object.assign(Object.assign({}, others), { alignSelf: others.alignSelf || ALIGN.START, style: styles(style, style.container, others.style) }), Touchable.displayName, useDevice()));
    return (React.createElement(Pressable, Object.assign({}, useBanStylerProps(others), testID(others.testID), { accessibilityRole: role, onPress: handlePress, pointerEvents: onPress ? others.pointerEvents : POINTER.NONE, style: ({ pressed }) => [styler, { opacity: pressed ? pressableOpacity : 1 }] }), children));
};
Touchable.displayName = 'Touchable';
export { Touchable };
