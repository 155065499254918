import StyleSheet from 'react-native-extended-stylesheet';
import { DefaultTheme } from './theme.default';
const THEME_NAME = '$theme';
const SHORTCUT = '$';
const getStyleSheet = (key) => {
    try {
        return StyleSheet.value(key);
    }
    catch (error) {
        return undefined;
    }
};
export const Theme = {
    get: () => {
        const value = {};
        Object.keys(DefaultTheme).forEach((k) => {
            const transformedKey = k;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            value[transformedKey.replace(SHORTCUT, '')] = getStyleSheet(transformedKey);
        });
        return value;
    },
    set: (theme = {}) => {
        StyleSheet.build(Object.assign(Object.assign(Object.assign({}, DefaultTheme), theme), { $theme: theme[THEME_NAME] || DefaultTheme[THEME_NAME] }));
    },
};
