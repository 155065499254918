const TRANSFORM_PROPS = ['rotate', 'scale', 'translateX', 'translateY'];
export const buildStyle = (value = {}, state = {}) => {
    if (Object.keys(value).length === 0 || Object.keys(state).length === 0)
        return;
    const style = {};
    Object.keys(value).forEach((key) => {
        if (TRANSFORM_PROPS.includes(key)) {
            style.transform = [
                ...(style.transform || []),
                {
                    [key]: key !== 'rotate' || (key === 'rotate' && !state[key].interpolate)
                        ? state[key]
                        : state[key].interpolate({
                            inputRange: [0, 1],
                            outputRange: ['0deg', '360deg'],
                        }),
                },
            ];
        }
        else
            style[key] = state[key];
    });
    return style;
};
