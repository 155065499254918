const getScreen = (height, width, rulers) => {
    const screen = { height, width, S: false, M: false, L: false };
    const rulerKeys = Object.keys(rulers || {});
    rulerKeys.forEach((rulerKey) => (screen[rulerKey] = false));
    rulerKeys.some((rulerKey) => {
        const [min = 0, max = 3840] = rulers[rulerKey];
        screen[rulerKey] = width >= min && width <= max;
        return screen[rulerKey];
    });
    return screen;
};
export { getScreen };
