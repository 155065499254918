var INFOBOX_VARIANT;
(function (INFOBOX_VARIANT) {
    INFOBOX_VARIANT["DEFAULT"] = "default";
    INFOBOX_VARIANT["PROMO"] = "promo";
    INFOBOX_VARIANT["WARNING"] = "warning";
})(INFOBOX_VARIANT || (INFOBOX_VARIANT = {}));
var INFOBOX_TYPE;
(function (INFOBOX_TYPE) {
    INFOBOX_TYPE["ROUNDED"] = "rounded";
    INFOBOX_TYPE["SQUARED"] = "squared";
})(INFOBOX_TYPE || (INFOBOX_TYPE = {}));
export { INFOBOX_VARIANT, INFOBOX_TYPE };
