import { Platform } from 'react-native';
import StyleSheet from 'react-native-extended-stylesheet';
const AVAILABLE_VARIANTS = ['lining-nums', 'oldstyle-nums', 'proportional-nums', 'small-caps', 'tabular-nums'];
const DEFAULT_FONT_FAMILY = 'Arial';
export const stylesheetFont = ({ nameSpace = '$fontBody', level = '' } = {}) => {
    const key = (attribute = '') => `${nameSpace}${attribute}${level}`;
    const fontName = StyleSheet.value(nameSpace);
    const fontSize = StyleSheet.value(key('Size'));
    const lineHeight = StyleSheet.value(key('Height'));
    const customFont = (StyleSheet.value('$fontMap') || {})[fontName];
    const customFontProps = !customFont
        ? {
            fontStyle: key('Style'),
            fontVariant: (StyleSheet.value(key('Variant')) || []).filter((variant) => AVAILABLE_VARIANTS.includes(variant)),
            fontWeight: key('Weight'),
        }
        : {};
    const fontFamily = customFont || fontName;
    return StyleSheet.create({
        font: Object.assign({ fontFamily: Platform.select({
                native: fontFamily,
                web: `${fontFamily},${DEFAULT_FONT_FAMILY}`,
            }), fontSize: key('Size'), lineHeight: Platform.select({
                native: lineHeight >= fontSize ? lineHeight : fontSize,
                web: lineHeight,
            }), letterSpacing: key('LetterSpacing'), paddingTop: key('PaddingTop'), paddingBottom: key('PaddingBottom'), paddingLeft: key('PaddingLeft'), paddingRight: key('PaddingRight') }, customFontProps),
    });
};
