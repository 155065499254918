import StyleSheet from 'react-native-extended-stylesheet';
export const style = StyleSheet.create({
    container: {
        overflow: 'hidden',
    },
    pressed: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
    },
});
