/* eslint-disable aurora-rebrand/deprecated-tokens */
export const LAYOUT_STYLESHEETS = {
    marginLayoutXXS: {
        margin: '$layoutXXS',
    },
    marginLayoutXS: {
        margin: '$layoutXS',
    },
    marginLayoutS: {
        margin: '$layoutS',
    },
    marginLayoutM: {
        margin: '$layoutM',
    },
    marginLayoutL: {
        margin: '$layoutL',
    },
    marginLayoutXL: {
        margin: '$layoutXL',
    },
    marginLayoutXXL: {
        margin: '$layoutXXL',
    },
    marginLayoutXXXL: {
        margin: '$layoutXXXL',
    },
    marginLayout0: {
        margin: 0,
    },
    marginTopLayout0: {
        marginTop: 0,
    },
    marginRightLayout0: {
        marginRight: 0,
    },
    marginBottomLayout0: {
        marginBottom: 0,
    },
    marginLeftLayout0: {
        marginLeft: 0,
    },
    marginTopLayoutXXS: {
        marginTop: '$layoutXXS',
    },
    marginRightLayoutXXS: {
        marginRight: '$layoutXXS',
    },
    marginBottomLayoutXXS: {
        marginBottom: '$layoutXXS',
    },
    marginLeftLayoutXXS: {
        marginLeft: '$layoutXXS',
    },
    marginTopLayoutXS: {
        marginTop: '$layoutXS',
    },
    marginRightLayoutXS: {
        marginRight: '$layoutXS',
    },
    marginBottomLayoutXS: {
        marginBottom: '$layoutXS',
    },
    marginLeftLayoutXS: {
        marginLeft: '$layoutXS',
    },
    marginTopLayoutS: {
        marginTop: '$layoutS',
    },
    marginRightLayoutS: {
        marginRight: '$layoutS',
    },
    marginBottomLayoutS: {
        marginBottom: '$layoutS',
    },
    marginLeftLayoutS: {
        marginLeft: '$layoutS',
    },
    marginTopLayoutM: {
        marginTop: '$layoutM',
    },
    marginRightLayoutM: {
        marginRight: '$layoutM',
    },
    marginBottomLayoutM: {
        marginBottom: '$layoutM',
    },
    marginLeftLayoutM: {
        marginLeft: '$layoutM',
    },
    marginTopLayoutL: {
        marginTop: '$layoutL',
    },
    marginRightLayoutL: {
        marginRight: '$layoutL',
    },
    marginBottomLayoutL: {
        marginBottom: '$layoutL',
    },
    marginLeftLayoutL: {
        marginLeft: '$layoutL',
    },
    marginTopLayoutXL: {
        marginTop: '$layoutXL',
    },
    marginRightLayoutXL: {
        marginRight: '$layoutXL',
    },
    marginBottomLayoutXL: {
        marginBottom: '$layoutXL',
    },
    marginLeftLayoutXL: {
        marginLeft: '$layoutXL',
    },
    marginTopLayoutXXL: {
        marginTop: '$layoutXXL',
    },
    marginRightLayoutXXL: {
        marginRight: '$layoutXXL',
    },
    marginBottomLayoutXXL: {
        marginBottom: '$layoutXXL',
    },
    marginLeftLayoutXXL: {
        marginLeft: '$layoutXXL',
    },
    marginTopLayoutXXXL: {
        marginTop: '$layoutXXXL',
    },
    marginRightLayoutXXXL: {
        marginRight: '$layoutXXXL',
    },
    marginBottomLayoutXXXL: {
        marginBottom: '$layoutXXXL',
    },
    marginLeftLayoutXXXL: {
        marginLeft: '$layoutXXXL',
    },
    paddingLayoutXXS: {
        padding: '$layoutXXS',
    },
    paddingLayoutXS: {
        padding: '$layoutXS',
    },
    paddingLayoutS: {
        padding: '$layoutS',
    },
    paddingLayoutM: {
        padding: '$layoutM',
    },
    paddingLayoutL: {
        padding: '$layoutL',
    },
    paddingLayoutXL: {
        padding: '$layoutXL',
    },
    paddingLayoutXXL: {
        padding: '$layoutXXL',
    },
    paddingLayoutXXXL: {
        padding: '$layoutXXXL',
    },
    paddingLayout0: {
        padding: 0,
    },
    paddingTopLayout0: {
        paddingTop: 0,
    },
    paddingRightLayout0: {
        paddingRight: 0,
    },
    paddingBottomLayout0: {
        paddingBottom: 0,
    },
    paddingLeftLayout0: {
        paddingLeft: 0,
    },
    paddingTopLayoutXXS: {
        paddingTop: '$layoutXXS',
    },
    paddingRightLayoutXXS: {
        paddingRight: '$layoutXXS',
    },
    paddingBottomLayoutXXS: {
        paddingBottom: '$layoutXXS',
    },
    paddingLeftLayoutXXS: {
        paddingLeft: '$layoutXXS',
    },
    paddingTopLayoutXS: {
        paddingTop: '$layoutXS',
    },
    paddingRightLayoutXS: {
        paddingRight: '$layoutXS',
    },
    paddingBottomLayoutXS: {
        paddingBottom: '$layoutXS',
    },
    paddingLeftLayoutXS: {
        paddingLeft: '$layoutXS',
    },
    paddingTopLayoutS: {
        paddingTop: '$layoutS',
    },
    paddingRightLayoutS: {
        paddingRight: '$layoutS',
    },
    paddingBottomLayoutS: {
        paddingBottom: '$layoutS',
    },
    paddingLeftLayoutS: {
        paddingLeft: '$layoutS',
    },
    paddingTopLayoutM: {
        paddingTop: '$layoutM',
    },
    paddingRightLayoutM: {
        paddingRight: '$layoutM',
    },
    paddingBottomLayoutM: {
        paddingBottom: '$layoutM',
    },
    paddingLeftLayoutM: {
        paddingLeft: '$layoutM',
    },
    paddingTopLayoutL: {
        paddingTop: '$layoutL',
    },
    paddingRightLayoutL: {
        paddingRight: '$layoutL',
    },
    paddingBottomLayoutL: {
        paddingBottom: '$layoutL',
    },
    paddingLeftLayoutL: {
        paddingLeft: '$layoutL',
    },
    paddingTopLayoutXL: {
        paddingTop: '$layoutXL',
    },
    paddingRightLayoutXL: {
        paddingRight: '$layoutXL',
    },
    paddingBottomLayoutXL: {
        paddingBottom: '$layoutXL',
    },
    paddingLeftLayoutXL: {
        paddingLeft: '$layoutXL',
    },
    paddingTopLayoutXXL: {
        paddingTop: '$layoutXXL',
    },
    paddingRightLayoutXXL: {
        paddingRight: '$layoutXXL',
    },
    paddingBottomLayoutXXL: {
        paddingBottom: '$layoutXXL',
    },
    paddingLeftLayoutXXL: {
        paddingLeft: '$layoutXXL',
    },
    paddingTopLayoutXXXL: {
        paddingTop: '$layoutXXXL',
    },
    paddingRightLayoutXXXL: {
        paddingRight: '$layoutXXXL',
    },
    paddingBottomLayoutXXXL: {
        paddingBottom: '$layoutXXXL',
    },
    paddingLeftLayoutXXXL: {
        paddingLeft: '$layoutXXXL',
    },
};
