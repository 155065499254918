import { Platform, StatusBar } from 'react-native';
import StyleSheet from 'react-native-extended-stylesheet';
export const style = StyleSheet.create({
    stack: {
        right: 0,
        alignSelf: 'center',
        left: 0,
        top: Platform.OS === 'android' ? StatusBar === null || StatusBar === void 0 ? void 0 : StatusBar.currentHeight : 0,
        marginHorizontal: 'auto',
        width: '100%',
    },
    maxWidth: {
        maxWidth: '$layoutMaxWidth / 2',
    },
});
