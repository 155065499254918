import StyleSheet from 'react-native-extended-stylesheet';
export const style = StyleSheet.create({
    overlay: {
        bottom: 0,
        left: 0,
        right: 0,
        top: 0,
        backgroundColor: '$colorBgOpacity',
    },
    touchable: {
        height: '100%',
        width: '100%',
    },
});
