var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { StyleSheet } from 'react-native';
import { styler } from './styler';
import styles from './useStyler.style';
import { useBanProps } from '../useBanProps';
export const useStyler = (_a = { style: undefined }, _displayName, device) => {
    var { style } = _a, props = __rest(_a, ["style"]);
    if (_displayName === void 0) { _displayName = ''; }
    const values = styler(useBanProps(props, ['pointer']), device === null || device === void 0 ? void 0 : device.screen).map((key) => styles[key]);
    if (style)
        values.push(...(Array.isArray(style) ? style : [style]));
    const stylesheets = values.filter((value) => typeof value !== 'object');
    const objectStyle = StyleSheet.flatten(values.filter((value) => typeof value === 'object'));
    if (Object.keys(objectStyle).length > 0)
        stylesheets.push(objectStyle);
    return {
        style: stylesheets,
    };
};
