// TODO: This is not a real "hook"
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useSupportedProps = (props = {}, supported = []) => {
    const safeProps = {};
    for (const prop in props) {
        if (supported.includes(prop))
            safeProps[prop] = props[prop];
    }
    return safeProps;
};
