// TODO: This is not a real "hook"
export const useBanProps = (props = {}, 
// eslint-disable-next-line @typescript-eslint/no-explicit-any
bannedProps = []) => {
    const safeProps = {};
    Object.keys(props).forEach((prop) => {
        if (!bannedProps.includes(prop))
            safeProps[prop] = props[prop];
    });
    return safeProps;
};
