import { Platform, useWindowDimensions } from 'react-native';
import { IS_JEST } from '../../helpers';
import { getBrowser, getScreen } from './helpers';
const DEFAULT_RULERS = {
    S: [undefined, 599],
    M: [600, 839],
    L: [840, undefined],
};
const android = Platform.OS === 'android';
const ios = Platform.OS === 'ios';
const web = Platform.OS === 'web';
const platform = {
    android,
    ios,
    native: ios || android,
    web,
};
const useDevice = () => {
    const { height, width } = useWindowDimensions();
    return {
        browser: getBrowser(),
        platform,
        screen: getScreen(IS_JEST ? 667 : height, IS_JEST ? 375 : width, DEFAULT_RULERS),
    };
};
export { useDevice, DEFAULT_RULERS };
