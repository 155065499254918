import React, { useEffect, useRef } from 'react';
import { Animated, View } from 'react-native';
import { Circle, Svg } from 'react-native-svg';
import { style } from './Spinner.style';
import { Theme } from '../../../theming/Theme';
const AnimatedSvg = Animated.createAnimatedComponent(Svg);
const AnimatedCircle = Animated.createAnimatedComponent(Circle);
const Spinner = ({ accessibilityLabel, testID, style: customStyle }) => {
    const { colorBgActionPrimaryActive: strokeColor, colorBgActionPrimaryDisabled: bgStrokeColor } = Theme.get();
    const animationDuration = 3000;
    const rotation = useRef(new Animated.Value(0));
    const dashoffset = useRef(new Animated.Value(0));
    useEffect(() => {
        Animated.parallel([
            Animated.loop(Animated.sequence([
                Animated.timing(rotation.current, {
                    toValue: 1,
                    duration: animationDuration,
                    useNativeDriver: true,
                }),
            ])),
            Animated.loop(Animated.sequence([
                Animated.timing(dashoffset.current, {
                    toValue: 1,
                    duration: animationDuration / 2,
                    useNativeDriver: true,
                }),
                Animated.timing(dashoffset.current, {
                    toValue: 0,
                    duration: animationDuration / 2,
                    useNativeDriver: true,
                }),
            ])),
        ]).start();
    }, []);
    const interpolatedRotation = rotation.current.interpolate({
        inputRange: [0, 1],
        outputRange: [`-90deg`, `${360 * 3 - 90}deg`],
        extrapolate: 'clamp',
    });
    const interpolatedDashOffset = dashoffset.current.interpolate({
        inputRange: [0, 1],
        outputRange: [0, -500],
        extrapolate: 'clamp',
    });
    return (React.createElement(View, { accessibilityLabel: accessibilityLabel, style: [style.spinner, customStyle], testID: testID },
        React.createElement(AnimatedSvg, { viewBox: "0 0 200 200", style: [style.spinnerSvg, { transform: [{ rotate: interpolatedRotation }] }] },
            React.createElement(Circle, { cx: "100", cy: "100", r: "95", fill: "none", stroke: bgStrokeColor, strokeWidth: 10 }),
            React.createElement(AnimatedCircle, { cx: "100", cy: "100", r: "95", strokeDasharray: `${95 * Math.PI * 2}`, fill: "none", stroke: strokeColor, strokeWidth: 10, strokeLinecap: "round", strokeDashoffset: interpolatedDashOffset }))));
};
Spinner.displayName = 'Spinner';
export { Spinner };
