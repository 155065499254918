var ROLES;
(function (ROLES) {
    ROLES["button"] = "button";
    ROLES["adjustable"] = "adjustable";
    ROLES["alert"] = "alert";
    ROLES["checkbox"] = "checkbox";
    ROLES["combobox"] = "combobox";
    ROLES["header"] = "header";
    ROLES["image"] = "image";
    ROLES["imagebutton"] = "imagebutton";
    ROLES["keyboardkey"] = "keyboardkey";
    ROLES["link"] = "link";
    ROLES["menu"] = "menu";
    ROLES["menubar"] = "menubar";
    ROLES["menuitem"] = "menuitem";
    ROLES["none"] = "none";
    ROLES["progressbar"] = "progressbar";
    ROLES["radio"] = "radio";
    ROLES["radiogroup"] = "radiogroup";
    ROLES["scrollbar"] = "scrollbar";
    ROLES["search"] = "search";
    ROLES["spinbutton"] = "spinbutton";
    ROLES["summary"] = "summary";
    ROLES["switch"] = "switch";
    ROLES["tab"] = "tab";
    ROLES["tablist"] = "tablist";
    ROLES["text"] = "text";
    ROLES["timer"] = "timer";
    ROLES["toolbar"] = "toolbar";
})(ROLES || (ROLES = {}));
var PRESSABLE_OPACITY;
(function (PRESSABLE_OPACITY) {
    PRESSABLE_OPACITY[PRESSABLE_OPACITY["DEFAULT"] = 0.7] = "DEFAULT";
    PRESSABLE_OPACITY[PRESSABLE_OPACITY["OPTION_IMAGE"] = 0.9] = "OPTION_IMAGE";
})(PRESSABLE_OPACITY || (PRESSABLE_OPACITY = {}));
export { PRESSABLE_OPACITY, ROLES };
