import { useState } from 'react';
import { PanResponder } from 'react-native';
import { calcGesture } from './helpers';
const GAP = 2;
const DEFAULT_GESTURE = {
    up: false,
    down: false,
    left: false,
    right: false,
    deltaX: 0,
    deltaY: 0,
    endX: 0,
    endY: 0,
};
export const useSwipe = ({ onSwiping, onSwiped } = {}) => {
    const [gesture, setGesture] = useState(undefined);
    const handleStart = (event, state) => {
        setGesture(Object.assign(Object.assign({}, DEFAULT_GESTURE), { startX: state.x0, startY: state.y0 }));
    };
    const handleMove = (event, state) => {
        if (!gesture)
            return;
        const nextGesture = calcGesture(gesture, state);
        setGesture(() => {
            onSwiping(nextGesture, event);
            return nextGesture;
        });
    };
    const handleEnd = (event) => {
        setGesture(() => {
            onSwiped(gesture, event);
            return undefined;
        });
    };
    const panResponder = PanResponder.create({
        onMoveShouldSetPanResponder: (event, { dx: x, dy: y }) => !(x < GAP && x > -GAP && y < GAP && y > -GAP),
        onStartShouldSetPanResponder: () => true,
        onPanResponderGrant: handleStart,
        onPanResponderMove: handleMove,
        // onPanResponderEnd: handleEnd, // * Seems is not mandatory if we use onPanResponderRelease
        // onPanResponderTerminate: handleEnd,  // * Another component has become the responder, so this gesture should be cancelled
        onPanResponderRelease: handleEnd,
    });
    return panResponder.panHandlers;
};
