export const TEXT_STYLESHEETS = {
    upperCase: {
        textTransform: 'uppercase',
    },
    textAlignLeft: {
        textAlign: 'left',
    },
    textAlignCenter: {
        textAlign: 'center',
    },
    textAlignRight: {
        textAlign: 'right',
    },
};
