var DOMAINS;
(function (DOMAINS) {
    DOMAINS["alignContent"] = "alignContent";
    DOMAINS["alignItems"] = "alignItems";
    DOMAINS["alignSelf"] = "alignSelf";
    DOMAINS["backgroundColor"] = "backgroundColor";
    DOMAINS["borderColor"] = "borderColor";
    DOMAINS["color"] = "color";
    DOMAINS["display"] = "display";
    DOMAINS["flex"] = "flex";
    DOMAINS["flexDirection"] = "flexDirection";
    DOMAINS["flexWrap"] = "flexWrap";
    DOMAINS["justifyContent"] = "justifyContent";
    DOMAINS["layer"] = "layer";
    DOMAINS["margin"] = "margin";
    DOMAINS["marginHorizontal"] = "marginHorizontal";
    DOMAINS["marginVertical"] = "marginVertical";
    DOMAINS["marginTop"] = "marginTop";
    DOMAINS["marginRight"] = "marginRight";
    DOMAINS["marginBottom"] = "marginBottom";
    DOMAINS["marginLeft"] = "marginLeft";
    DOMAINS["padding"] = "padding";
    DOMAINS["paddingHorizontal"] = "paddingHorizontal";
    DOMAINS["paddingVertical"] = "paddingVertical";
    DOMAINS["paddingTop"] = "paddingTop";
    DOMAINS["paddingRight"] = "paddingRight";
    DOMAINS["paddingBottom"] = "paddingBottom";
    DOMAINS["paddingLeft"] = "paddingLeft";
    DOMAINS["pointer"] = "pointer";
    DOMAINS["position"] = "position";
    DOMAINS["upperCase"] = "upperCase";
    DOMAINS["textAlign"] = "textAlign";
    DOMAINS["wide"] = "wide";
})(DOMAINS || (DOMAINS = {}));
var DOMAIN_SHORTCUT;
(function (DOMAIN_SHORTCUT) {
    DOMAIN_SHORTCUT["backgroundColor"] = "bg";
    DOMAIN_SHORTCUT["borderColor"] = "bc";
    DOMAIN_SHORTCUT["zIndex"] = "z";
})(DOMAIN_SHORTCUT || (DOMAIN_SHORTCUT = {}));
var ALIGN;
(function (ALIGN) {
    ALIGN["AROUND"] = "around";
    ALIGN["AUTO"] = "auto";
    ALIGN["BETWEEN"] = "between";
    ALIGN["STRETCH"] = "stretch";
    ALIGN["START"] = "start";
    ALIGN["CENTER"] = "center";
    ALIGN["END"] = "end";
    ALIGN["TOP"] = "top";
    ALIGN["RIGHT"] = "right";
    ALIGN["BOTTOM"] = "bottom";
    ALIGN["LEFT"] = "left";
})(ALIGN || (ALIGN = {}));
var COLOR;
(function (COLOR) {
    // TODO: TO DEPRECATE
    COLOR["BASE"] = "base";
    COLOR["CONTENT"] = "content";
    COLOR["ACCENT"] = "accent";
    COLOR["PRIMARY"] = "primary";
    COLOR["ALERT"] = "alert";
    COLOR["INFO"] = "info";
    COLOR["GRAYSCALE_XL"] = "grayscaleXL";
    COLOR["GRAYSCALE_L"] = "grayscaleL";
    COLOR["GRAYSCALE_M"] = "grayscaleM";
    COLOR["GRAYSCALE_S"] = "grayscaleS";
    COLOR["OVERLAY"] = "overlay";
    COLOR["TOUCHABLE"] = "touchable";
    COLOR["BG_ACTION_PRIMARY_ACTIVE"] = "bgActionPrimaryActive";
    COLOR["BG_ACTION_PRIMARY_DISABLED"] = "bgActionPrimaryDisabled";
    COLOR["BG_ACTION_PRIMARY_HOVER"] = "bgActionPrimaryHover";
    COLOR["BG_ACTION_PRIMARY_PRESSED"] = "bgActionPrimaryPressed";
    COLOR["BG_ACTION_SECONDARY_ACTIVE"] = "bgActionSecondaryActive";
    COLOR["BG_ACTION_SECONDARY_DISABLED"] = "bgActionSecondaryDisabled";
    COLOR["BG_ACTION_SECONDARY_HOVER"] = "bgActionSecondaryHover";
    COLOR["BG_ACTION_SECONDARY_PRESSED"] = "bgActionSecondaryPressed";
    COLOR["BG_BASE"] = "bgBase";
    COLOR["BG_BURNT"] = "bgBurnt";
    COLOR["BG_ERROR"] = "bgError";
    COLOR["BG_ERROR_LIGHT"] = "bgErrorLight";
    COLOR["BG_INTERACTIVE_ACTIVE"] = "bgInteractiveActive";
    COLOR["BG_INTERACTIVE_DISABLED"] = "bgInteractiveDisabled";
    COLOR["BG_INTERACTIVE_HOVER"] = "bgInteractiveHover";
    COLOR["BG_INTERACTIVE_SELECTED"] = "bgInteractiveSelected";
    COLOR["BG_OLIVE"] = "bgOlive";
    COLOR["BG_OPACITY"] = "bgOpacity";
    COLOR["BG_OPACITY_LIGHT"] = "bgOpacityLight";
    COLOR["BG_PRIMARY"] = "bgPrimary";
    COLOR["BG_PRIMARY_LIGHT"] = "bgPrimaryLight";
    COLOR["BG_PRIMARY_MEDIUM"] = "bgPrimaryMedium";
    COLOR["BG_PRIMARY_MEDIUM_LIGHT"] = "bgPrimaryMediumLight";
    COLOR["BG_PROMOTION"] = "bgPromotion";
    COLOR["BG_ROSE"] = "bgRose";
    COLOR["BG_SUCCESS"] = "bgSuccess";
    COLOR["BG_SURFACE"] = "bgSurface";
    COLOR["BG_SURFACE_LIGHT"] = "bgSurfaceLight";
    COLOR["BG_WARNING"] = "bgWarning";
    COLOR["BG_WARNING_LIGHT"] = "bgWarningLight";
    COLOR["BORDER_ACTION_SECONDARY"] = "borderActionSecondary";
    COLOR["BORDER_INPUT"] = "borderInput";
    COLOR["BORDER_INPUT_ERROR"] = "borderInputError";
    COLOR["BORDER_INPUT_FOCUS"] = "borderInputFocus";
    COLOR["BORDER_INTERACTIVE"] = "borderInteractive";
    COLOR["BORDER_INTERACTIVE_SELECTED"] = "borderInteractiveSelected";
    COLOR["ICON"] = "icon";
    COLOR["ICON_DISABLED"] = "iconDisabled";
    COLOR["ICON_ERROR"] = "iconError";
    COLOR["ICON_HIGHLIGHT"] = "iconHighlight";
    COLOR["ICON_LIGHT"] = "iconLight";
    COLOR["ICON_ON_PRIMARY"] = "iconOnPrimary";
    COLOR["ICON_OPACITY"] = "iconOpacity";
    COLOR["ICON_OPACITY_LIGHT"] = "iconOpacityLight";
    COLOR["ICON_PROMOTION"] = "iconPromotion";
    COLOR["ICON_SUCCESS"] = "iconSuccess";
    COLOR["ICON_WARNING"] = "iconWarning";
    COLOR["TEXT"] = "text";
    COLOR["TEXT_ACTION_DISABLED"] = "textActionDisabled";
    COLOR["TEXT_ACTION_PRIMARY"] = "textActionPrimary";
    COLOR["TEXT_ACTION_SECONDARY"] = "textActionSecondary";
    COLOR["TEXT_DISABLED"] = "textDisabled";
    COLOR["TEXT_ERROR"] = "textError";
    COLOR["TEXT_LIGHT"] = "textLight";
    COLOR["TEXT_MEDIUM"] = "textMedium";
    COLOR["TEXT_MEDIUM_LIGHT"] = "textMediumLight";
    COLOR["TEXT_OPACITY"] = "textOpacity";
    COLOR["TEXT_OPACITY_LIGHT"] = "textOpacityLight";
    COLOR["TEXT_PROMOTION"] = "textPromotion";
})(COLOR || (COLOR = {}));
var DISPLAY;
(function (DISPLAY) {
    DISPLAY["BLOCK"] = "block";
    DISPLAY["INLINE"] = "inline";
    DISPLAY["INLINE_BLOCK"] = "inlineBlock";
    DISPLAY["FLEX"] = "flex";
    DISPLAY["NONE"] = "none";
})(DISPLAY || (DISPLAY = {}));
var COORD;
(function (COORD) {
    COORD["TOP"] = "Top";
    COORD["RIGHT"] = "Right";
    COORD["BOTTOM"] = "Bottom";
    COORD["LEFT"] = "Left";
})(COORD || (COORD = {}));
var FLEX_DIRECTION;
(function (FLEX_DIRECTION) {
    FLEX_DIRECTION["COLUMN"] = "column";
    FLEX_DIRECTION["ROW"] = "row";
})(FLEX_DIRECTION || (FLEX_DIRECTION = {}));
var POINTER;
(function (POINTER) {
    POINTER["ALL"] = "all";
    POINTER["AUTO"] = "auto";
    POINTER["NONE"] = "none";
})(POINTER || (POINTER = {}));
var SPACE;
(function (SPACE) {
    SPACE["SPACE_025"] = "space025";
    SPACE["SPACE_05"] = "space05";
    SPACE["SPACE_1"] = "space1";
    SPACE["SPACE_2"] = "space2";
    SPACE["SPACE_3"] = "space3";
    SPACE["SPACE_4"] = "space4";
    SPACE["SPACE_5"] = "space5";
    SPACE["SPACE_6"] = "space6";
    SPACE["SPACE_8"] = "space8";
    SPACE["SPACE_10"] = "space10";
    SPACE["SPACE_12"] = "space12";
    SPACE["SPACE_16"] = "space16";
    SPACE["SPACE_20"] = "space20";
    SPACE["SPACE_24"] = "space24";
    SPACE["SPACE_28"] = "space28";
    SPACE["SPACE_32"] = "space32";
})(SPACE || (SPACE = {}));
var SIZE;
(function (SIZE) {
    SIZE["XXXS"] = "XXXS";
    SIZE["XXS"] = "XXS";
    SIZE["XS"] = "XS";
    SIZE["S"] = "S";
    SIZE["M"] = "M";
    SIZE["L"] = "L";
    SIZE["XL"] = "XL";
    SIZE["XXL"] = "XXL";
    SIZE["XXXL"] = "XXXL";
    SIZE["RESET"] = "0";
})(SIZE || (SIZE = {}));
var LAYOUT;
(function (LAYOUT) {
    LAYOUT["XXXS"] = "layoutXXXS";
    LAYOUT["XXS"] = "layoutXXS";
    LAYOUT["XS"] = "layoutXS";
    LAYOUT["S"] = "layoutS";
    LAYOUT["M"] = "layoutM";
    LAYOUT["L"] = "layoutL";
    LAYOUT["XL"] = "layoutXL";
    LAYOUT["XXL"] = "layoutXXL";
    LAYOUT["XXXL"] = "layoutXXXL";
})(LAYOUT || (LAYOUT = {}));
var POSITION;
(function (POSITION) {
    POSITION["ABSOLUTE"] = "absolute";
    POSITION["FIXED"] = "fixed";
    POSITION["RELATIVE"] = "relative";
    POSITION["STICKY"] = "sticky";
})(POSITION || (POSITION = {}));
var FLEX_WRAP;
(function (FLEX_WRAP) {
    FLEX_WRAP["WRAP"] = "wrap";
})(FLEX_WRAP || (FLEX_WRAP = {}));
var FONT;
(function (FONT) {
    FONT["HEADING"] = "heading";
    FONT["BODY"] = "body";
    FONT["ACTION"] = "action";
    FONT["DETAIL"] = "detail";
    FONT["DETAIL_BOLD"] = "detailBold";
})(FONT || (FONT = {}));
export { DOMAINS, DOMAIN_SHORTCUT, ALIGN, COLOR, COORD, DISPLAY, FLEX_DIRECTION, FLEX_WRAP, FONT, POINTER, POSITION, SIZE, SPACE, LAYOUT, };
