const PORTAL_REDUCER_TYPE = {
    MOUNT: 'mount',
    UNMOUNT: 'unmount',
};
const PortalReducer = (state = [], action = {}) => {
    const { type, id, component } = action;
    switch (type) {
        case PORTAL_REDUCER_TYPE.MOUNT:
            return [...state, { id, component }];
        case PORTAL_REDUCER_TYPE.UNMOUNT:
            return state.filter((s) => s.id !== id);
        default:
            throw new Error(`Unhandled action type: ${type}`);
    }
};
export { PORTAL_REDUCER_TYPE, PortalReducer };
