const BANNED_PROPS = [
    'aria-controls',
    'aria-disabled',
    'aria-expanded',
    'aria-label',
    'aria-live',
    'id',
    'pointer',
    'pointerEvents',
    'tabIndex',
];
var MOTION_TIMING;
(function (MOTION_TIMING) {
    MOTION_TIMING["LINEAR"] = "linear";
    MOTION_TIMING["SPRING"] = "spring";
    MOTION_TIMING["QUAD"] = "quad";
})(MOTION_TIMING || (MOTION_TIMING = {}));
var MOTION_TYPES;
(function (MOTION_TYPES) {
    MOTION_TYPES["EXPAND"] = "expand";
    MOTION_TYPES["COLLAPSE"] = "collapse";
    MOTION_TYPES["SKELETON"] = "skeleton";
    MOTION_TYPES["SLOW"] = "slow";
})(MOTION_TYPES || (MOTION_TYPES = {}));
export { BANNED_PROPS, MOTION_TIMING, MOTION_TYPES };
