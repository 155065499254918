var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react-hooks/rules-of-hooks */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { Children, cloneElement, isValidElement } from 'react';
import { Text as BaseText, Platform } from 'react-native';
import { capitalize, stylesheetColor, stylesheetFont, testID } from '../../../helpers';
import { POINTER, 
// Methods
styles, 
// Hooks
useBanProps, useBanStylerProps, useDevice, useStyler, } from '../../../hooks';
import { BANNED_PROPS } from './Text.definition';
import { style } from './Text.style';
const Text = (props) => {
    if (isValidElement(props.children)) {
        const { children } = props, clonedProps = __rest(props, ["children"]);
        return cloneElement(children, clonedProps);
    }
    const { action, align, color, children = '', detail, detailBold, displayName = undefined, heading, hidden, level = 3, ellipsizeMode, lineThrough, role, selectable = false, underlined } = props, others = __rest(props, ["action", "align", "color", "children", "detail", "detailBold", "displayName", "heading", "hidden", "level", "ellipsizeMode", "lineThrough", "role", "selectable", "underlined"]);
    const pointerEvents = Platform.OS === 'ios'
        ? undefined
        : others.pointerEvents || (!selectable || Platform.OS !== 'web' ? POINTER.NONE : undefined);
    return (React.createElement(BaseText, Object.assign({}, useBanStylerProps(useBanProps(others, BANNED_PROPS)), useStyler(Object.assign(Object.assign({}, others), { style: styles(stylesheetFont({
            nameSpace: `$font${capitalize(heading ? 'heading' : action ? 'action' : detail ? 'detail' : detailBold ? 'detailBold' : 'body')}`,
            level: level.toString(),
        }).font, stylesheetColor(color).color, hidden && style.hidden, lineThrough && style.lineThrough, underlined && style.underlined, align && style[align], others.style) }), displayName, useDevice()), testID(others.testID), (ellipsizeMode ? { numberOfLines: 1 } : undefined), { accessibilityRole: Platform.OS === 'web' ? role : undefined, allowFontScaling: false, pointerEvents: pointerEvents, selectable: selectable }), Array.isArray(children)
        ? Children.map(children, (child) => {
            const parentProps = __rest(props, []);
            return isValidElement(child) ? cloneElement(child, Object.assign(Object.assign({}, parentProps), child.props)) : child;
        })
        : children));
};
Text.displayName = 'Text';
export { Text };
