const BANNED_PROPS = ['as', 'id'];
const STYLE_BANNED_PROPS = [
    'padding',
    'paddingVertical',
    'paddingHorizontal',
    'paddingTop',
    'paddingRight',
    'paddingBottom',
    'paddingLeft',
];
export { BANNED_PROPS, STYLE_BANNED_PROPS };
