var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { ScrollView as BaseScrollView, Platform } from 'react-native';
import { IS_JEST, testID } from '../../../helpers';
import { styles, useBanProps, useBanStylerProps, useDevice, useStyler, useSupportedProps } from '../../../hooks';
import { BANNED_PROPS, STYLE_BANNED_PROPS } from './ScrollView.definition';
const ScrollView = forwardRef((_a, theForwardRef) => {
    var { animated = true, children, height, scrollIndicator = false, horizontal, role, scrollEventThrottle = Platform.OS === 'ios' ? 0 : undefined, scrollTo, scrollToEnd, snapInterval, width, onScroll } = _a, others = __rest(_a, ["animated", "children", "height", "scrollIndicator", "horizontal", "role", "scrollEventThrottle", "scrollTo", "scrollToEnd", "snapInterval", "width", "onScroll"]);
    const ref = useRef();
    useImperativeHandle(theForwardRef, () => ref.current);
    useEffect(() => {
        const { current } = ref;
        if (current && (scrollTo !== undefined || scrollToEnd)) {
            if (IS_JEST && Platform.OS === 'web')
                return;
            if (scrollToEnd)
                current.scrollToEnd({ animated });
            else
                current.scrollTo({ [horizontal ? 'x' : 'y']: scrollTo, animated });
        }
    }, [animated, horizontal, scrollTo, scrollToEnd, ref]);
    const handleScroll = (event = {}) => {
        const { layoutMeasurement: { height: offsetHeight, width: offsetWidth } = {}, contentOffset: { x: X, y: Y } = {}, contentSize: { height: scrollHeight, width: scrollWidth } = {}, } = event.nativeEvent || {};
        const percentX = X > 0 ? Math.floor((X * 100) / (scrollWidth - offsetWidth)) : 0;
        const percentY = Y > 0 ? Math.floor((Y * 100) / (scrollHeight - offsetHeight)) : 0;
        if (snapInterval === undefined ||
            percentX === 100 ||
            percentY === 100 ||
            parseInt((horizontal ? X : Y) % snapInterval, 10) === 0)
            onScroll({ X, Y, percentX, percentY }, event);
    };
    return (React.createElement(BaseScrollView, Object.assign({}, useBanProps(useBanStylerProps(others), BANNED_PROPS), useStyler(Object.assign(Object.assign({}, useBanProps(others, STYLE_BANNED_PROPS)), { style: styles({ height, maxHeight: height, maxWidth: width, width }, others.style) }), ScrollView.displayName, useDevice()), testID(others.testID), (snapInterval
        ? {
            decelerationRate: 'fast',
            pagingEnabled: Platform.OS === 'web' || (Platform.OS === 'android' && horizontal),
            snapToAlignment: 'start',
            snapToInterval: snapInterval,
        }
        : {}), { contentContainerStyle: useStyler(Object.assign(Object.assign({}, useSupportedProps(others, STYLE_BANNED_PROPS)), { style: others.contentContainerStyle }), ScrollView.displayName, useDevice()).style, accessibilityRole: Platform.OS === 'web' ? role : undefined, horizontal: horizontal, ref: ref, scrollEventThrottle: scrollEventThrottle, showsHorizontalScrollIndicator: scrollIndicator, showsVerticalScrollIndicator: scrollIndicator, onScroll: onScroll ? handleScroll : undefined }), children));
});
ScrollView.displayName = 'ScrollView';
export { ScrollView };
