import StyleSheet from 'react-native-extended-stylesheet';
export const style = StyleSheet.create({
    motion: {
        height: '100%',
        overflow: 'scroll',
        width: '100%',
    },
    overlay: {
        paddingRight: `$space12`,
    },
    overlayRight: {
        paddingLeft: `$space12`,
    },
    panel: {
        left: 0,
        height: '100%',
        top: 0,
    },
    panelRight: {
        alignItems: 'flex-end',
    },
    safeAreaView: {
        flex: 1,
    },
    swiper: {
        bottom: '$space24',
        right: 0,
        top: '$space24',
        width: '$space8',
    },
    swiperRight: {
        right: null,
        left: 0,
    },
});
