var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { COLOR, DISPLAY, POSITION, styles } from '../../../hooks';
import { Icon, MOTION_TIMING, Motion, Touchable, View } from '../../primitives';
import { style } from './ButtonIcon.style';
const ButtonIcon = (_a) => {
    var { color, colorNotification = COLOR.ICON_ERROR, name, notification, small } = _a, others = __rest(_a, ["color", "colorNotification", "name", "notification", "small"]);
    return (React.createElement(Touchable, Object.assign({}, others, { style: styles(style.container, small && style.small, others.style) }),
        React.createElement(View, { position: POSITION.RELATIVE },
            React.createElement(Icon, { color: color, display: DISPLAY.BLOCK, name: name }),
            React.createElement(Motion, { backgroundColor: colorNotification, borderColor: COLOR.ICON_ON_PRIMARY, position: POSITION.ABSOLUTE, style: style.notification, timing: MOTION_TIMING.SPRING, value: { scale: notification ? 1 : 0 } }))));
};
ButtonIcon.displayName = 'ButtonIcon';
export { ButtonIcon };
