const BANNED_PROPS = ['as', 'href', 'htmlFor', 'id', 'style'];
var TEXT_STYLE;
(function (TEXT_STYLE) {
    TEXT_STYLE["color"] = "color";
    TEXT_STYLE["fontFamily"] = "fontFamily";
    TEXT_STYLE["fontSize"] = "fontSize";
    TEXT_STYLE["fontStyle"] = "fontStyle";
    TEXT_STYLE["fontWeight"] = "fontWeight";
    TEXT_STYLE["letterSpacing"] = "letterSpacing";
    TEXT_STYLE["lineHeight"] = "lineHeight";
    TEXT_STYLE["textAlign"] = "textAlign";
    TEXT_STYLE["textDecorationLine"] = "textDecorationLine";
    TEXT_STYLE["textDecorationStyle"] = "textDecorationStyle";
    TEXT_STYLE["textDecorationColor"] = "textDecorationColor";
    TEXT_STYLE["textShadowColor"] = "textShadowColor";
    TEXT_STYLE["textShadowOffset"] = "textShadowOffset";
    TEXT_STYLE["textShadowRadius"] = "textShadowRadius";
    TEXT_STYLE["textTransform"] = "textTransform";
})(TEXT_STYLE || (TEXT_STYLE = {}));
var TEXT_ROLES;
(function (TEXT_ROLES) {
    TEXT_ROLES["a"] = "a";
    TEXT_ROLES["abbr"] = "abbr";
    TEXT_ROLES["address"] = "address";
    TEXT_ROLES["blockquote"] = "blockquote";
    TEXT_ROLES["caption"] = "caption";
    TEXT_ROLES["cite"] = "cite";
    TEXT_ROLES["bdo"] = "bdo";
    TEXT_ROLES["del"] = "del";
    TEXT_ROLES["em"] = "em";
    TEXT_ROLES["figcaption"] = "figcaption";
    TEXT_ROLES["h1"] = "h1";
    TEXT_ROLES["h2"] = "h2";
    TEXT_ROLES["h3"] = "h3";
    TEXT_ROLES["h4"] = "h4";
    TEXT_ROLES["h5"] = "h5";
    TEXT_ROLES["h6"] = "h6";
    TEXT_ROLES["ins"] = "ins";
    TEXT_ROLES["label"] = "label";
    TEXT_ROLES["legend"] = "legend";
    TEXT_ROLES["mark"] = "mark";
    TEXT_ROLES["option"] = "option";
    TEXT_ROLES["optgroup"] = "optgroup";
    TEXT_ROLES["i"] = "i";
    TEXT_ROLES["p"] = "p";
    TEXT_ROLES["q"] = "q";
    TEXT_ROLES["small"] = "small";
    TEXT_ROLES["span"] = "span";
    TEXT_ROLES["strong"] = "strong";
    TEXT_ROLES["sub"] = "sub";
    TEXT_ROLES["sup"] = "sup";
    TEXT_ROLES["th"] = "th";
    TEXT_ROLES["td"] = "td";
})(TEXT_ROLES || (TEXT_ROLES = {}));
export { BANNED_PROPS, TEXT_STYLE, TEXT_ROLES };
