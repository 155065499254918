var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { COLOR, DISPLAY, FLEX_DIRECTION, SPACE, styles } from '../../../hooks';
import { Icon, TEXT_ROLES, Text, View } from '../../primitives';
import { INFOBOX_TYPE, INFOBOX_VARIANT } from './InfoBox.definition';
import { borderRadiusVariantStyle, contentAlignStyle, style } from './InfoBox.style';
import { ALIGN } from '../../../hooks/useStyler/styler.definition';
const infoBoxBackgroundColor = {
    default: COLOR.BG_SURFACE,
    promo: COLOR.BG_PROMOTION,
    warning: COLOR.BG_WARNING,
};
const infoBoxColor = {
    default: COLOR.TEXT,
    promo: COLOR.TEXT_PROMOTION,
    warning: COLOR.TEXT,
};
export const InfoBox = (_a) => {
    var { children, contentAlign = ALIGN.LEFT, icon, title, text, type = INFOBOX_TYPE.ROUNDED, variant = INFOBOX_VARIANT.DEFAULT } = _a, others = __rest(_a, ["children", "contentAlign", "icon", "title", "text", "type", "variant"]);
    return (React.createElement(View, Object.assign({}, others),
        React.createElement(View, { backgroundColor: infoBoxBackgroundColor[variant], style: styles(style.container, borderRadiusVariantStyle[type]), wide: true },
            React.createElement(View, { style: contentAlignStyle[contentAlign], flexDirection: FLEX_DIRECTION.ROW },
                icon && React.createElement(Icon, { name: icon, color: infoBoxColor[variant], marginRight: SPACE.SPACE_2 }),
                React.createElement(View, { style: style.textContainer },
                    title && (React.createElement(Text, { role: TEXT_ROLES.strong, color: infoBoxColor[variant], detailBold: true, level: 1, marginBottom: text ? SPACE.SPACE_05 : undefined, style: style.paddingTop }, title)),
                    text && (React.createElement(Text, { color: infoBoxColor[variant], detail: true, display: DISPLAY.BLOCK, level: 2, style: [!title && style.paddingTop] }, text)),
                    children && React.createElement(View, { marginTop: SPACE.SPACE_1 }, children))))));
};
InfoBox.displayName = 'InfoBox';
