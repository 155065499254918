const STACK_REDUCER = {
    MOUNT: 'mount',
    SHOW: 'show',
    HIDE: 'hide',
    UNMOUNT: 'unmount',
    UNMOUNT_ALL: 'unmount_all',
};
const StackReducer = (state, action) => {
    const { type } = action;
    switch (type) {
        case STACK_REDUCER.MOUNT:
            return Object.assign(Object.assign({}, state), { [action === null || action === void 0 ? void 0 : action.id]: { component: action === null || action === void 0 ? void 0 : action.component, props: Object.assign(Object.assign({}, action === null || action === void 0 ? void 0 : action.props), { isVisible: false }) } });
        case STACK_REDUCER.SHOW:
            return Object.assign(Object.assign({}, state), { [action === null || action === void 0 ? void 0 : action.id]: Object.assign(Object.assign({}, state[action === null || action === void 0 ? void 0 : action.id]), { props: Object.assign(Object.assign({}, state[action === null || action === void 0 ? void 0 : action.id].props), { isVisible: true }) }) });
        case STACK_REDUCER.HIDE:
            return state[action === null || action === void 0 ? void 0 : action.id]
                ? Object.assign(Object.assign({}, state), { [action === null || action === void 0 ? void 0 : action.id]: Object.assign(Object.assign({}, state[action === null || action === void 0 ? void 0 : action.id]), { props: Object.assign(Object.assign({}, state[action === null || action === void 0 ? void 0 : action.id].props), { isVisible: false }) }) }) : state;
        case STACK_REDUCER.UNMOUNT:
            delete state[action === null || action === void 0 ? void 0 : action.id];
            return Object.assign({}, state);
        case STACK_REDUCER.UNMOUNT_ALL:
            return {};
        default:
            throw new Error(`Unhandled action type: ${type}`);
    }
};
export { STACK_REDUCER, StackReducer };
