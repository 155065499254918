import StyleSheet from 'react-native-extended-stylesheet';
export const style = StyleSheet.create({
    placeholder: {
        alignSelf: 'center',
        paddingTop: '$space1',
        position: 'absolute',
    },
    input: {
        paddingLeft: '$space1',
        paddingRight: '$space1',
    },
    length2: {
        width: () => StyleSheet.value('$fontInputSize * 2') + StyleSheet.value('$space1 * 2'),
    },
    length4: {
        width: () => StyleSheet.value('$fontInputSize * 4') - StyleSheet.value('$space2 + $space05'),
    },
    firstInput: {
        marginLeft: '$space4',
        paddingLeft: 0,
        paddingRight: 0,
    },
    lastInput: {
        minWidth: '50%',
    },
    inputSeparator: {
        paddingTop: '$space1',
    },
});
