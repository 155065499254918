var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState } from 'react';
import { uncapitalize } from '../../../helpers';
import { ALIGN, POINTER, SPACE, styles } from '../../../hooks';
import { Icon, MOTION_TYPES, Motion, PRESSABLE_OPACITY, Text, Touchable, View } from '../../primitives';
import { BUTTON_VARIANT } from './Button.definition';
import { buttonVariantStyle, contentVariantStyle, contentVariantStyleRecord, style } from './Button.style';
import { COLOR } from '../../../hooks/useStyler/styler.definition';
const Button = (_a) => {
    var { busy, children, disabled, icon, level = 3, small, variant = BUTTON_VARIANT.PRIMARY, wide = true, onPress } = _a, others = __rest(_a, ["busy", "children", "disabled", "icon", "level", "small", "variant", "wide", "onPress"]);
    const [layout, setLayout] = useState();
    const handleLayout = ({ nativeEvent }) => {
        if (nativeEvent)
            setLayout(nativeEvent.layout);
    };
    const iconColor = uncapitalize(contentVariantStyleRecord[disabled ? 'disabled' : variant].color.replace(/\$color/g, ''));
    return (React.createElement(Touchable, Object.assign({}, others, { style: [
            style.base,
            buttonVariantStyle[variant],
            disabled && buttonVariantStyle.disabled,
            small && style.small,
            !wide && style.fit,
            others.style,
        ], wide: wide, onLayout: handleLayout, onPress: !busy && !disabled ? onPress : undefined, pressableOpacity: disabled ? 1 : PRESSABLE_OPACITY.DEFAULT }),
        React.createElement(Motion, { backgroundColor: variant === BUTTON_VARIANT.PRIMARY ? COLOR.BG_ACTION_PRIMARY_PRESSED : COLOR.BG_ACTION_SECONDARY_PRESSED, disabled: !busy, layout: layout, pointerEvents: POINTER.NONE, style: styles(style.busy, small && style.small), type: MOTION_TYPES.SLOW, value: { width: busy ? '100%' : '0%' } }),
        React.createElement(View, { style: style.content },
            icon && React.createElement(Icon, { marginRight: SPACE.SPACE_4, color: iconColor, name: icon }),
            typeof children === 'string' ? (React.createElement(Text, { action: true, align: ALIGN.CENTER, level: level, selectable: false, style: [contentVariantStyle[variant], disabled && contentVariantStyle.disabled] }, children)) : (children))));
};
Button.displayName = 'Button';
export { Button };
