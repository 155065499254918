var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { Fragment, useMemo } from 'react';
import { View } from 'react-native';
import { PortalProvider, StackProvider } from '../../../contexts';
import { styles, useBanProps, useBanStylerProps, useStyler } from '../../../hooks';
import { DefaultTheme, Theme } from '../../../theming';
import { BANNED_PROPS } from './Aurora.definition';
import { style } from './Aurora.style';
const Aurora = (_a) => {
    var { children, theme = DefaultTheme, useStack = true, usePortal = true } = _a, others = __rest(_a, ["children", "theme", "useStack", "usePortal"]);
    useMemo(() => Theme.set(theme), [theme]);
    const Stack = useStack ? StackProvider : Fragment;
    const Portal = usePortal ? PortalProvider : Fragment;
    return (React.createElement(View, Object.assign({}, useBanProps(useBanStylerProps(others), BANNED_PROPS), useStyler(Object.assign(Object.assign({}, others), { style: styles(style.container, others.style) }))),
        React.createElement(Stack, null,
            React.createElement(Portal, null, children))));
};
Aurora.displayName = 'Aurora';
export { Aurora };
