const REGEX_DELIMITER = /\s*,\s*/;
const getLargestUri = (sources = []) => {
    var _a, _b;
    const sizeUri = (_b = (_a = sources.find((source) => source.pixelDensity === 1)) === null || _a === void 0 ? void 0 : _a.uri) !== null && _b !== void 0 ? _b : '';
    const biggestSource = sources.sort((a, b) => {
        if (a.pixelDensity === b.pixelDensity) {
            return 0;
        }
        return a.pixelDensity < b.pixelDensity ? 1 : -1;
    })[0];
    return {
        uri: (biggestSource === null || biggestSource === void 0 ? void 0 : biggestSource.uri) || '',
        sizeUri,
    };
};
const toSources = (srcSet = '') => srcSet.split(REGEX_DELIMITER).reduce((array, src) => {
    const [uri, pixelDensityRaw] = src.split(' ');
    if (!pixelDensityRaw)
        return [...array, { pixelDensity: 1, uri }];
    const pixelDensity = parseFloat(pixelDensityRaw.replace('x', ''));
    return [...array, { pixelDensity, uri }];
}, []);
export const getUri = (src, srcSet) => srcSet ? getLargestUri(toSources(srcSet)) : { uri: src, sizeUri: src };
