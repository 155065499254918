import StyleSheet from 'react-native-extended-stylesheet';
export const style = StyleSheet.create({
    header: {
        height: '$space12',
        maxWidth: '$layoutMaxWidth',
        paddingHorizontal: '$space4',
        width: '100%',
    },
    actions: {
        marginLeft: 'auto',
    },
    fullWidth: {
        maxWidth: '100%',
    },
    title: {
        left: '$space12',
        right: '$space12',
    },
});
