const MATCH_SAFARI = /^((?!chrome|android).)*safari/i;
const MATCH_MOBILE = /^Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile/i;
const FB_IN_APP = /fb_iab|fban/;
const PINTEREST_IN_APP = /pinterest/;
const getBrowser = () => {
    const { userAgent = '' } = typeof navigator !== 'undefined' ? navigator : {};
    const ua = userAgent.toLowerCase();
    const safari = Boolean(ua.match(MATCH_SAFARI));
    return {
        chrome: !safari && Boolean(ua.indexOf('chrome') !== -1),
        firefox: Boolean(ua.indexOf('firefox') !== -1),
        inApp: FB_IN_APP.test(ua) || PINTEREST_IN_APP.test(ua),
        mobile: Boolean(ua.match(MATCH_MOBILE)),
        safari,
    };
};
export { getBrowser };
