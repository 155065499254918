var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useLayoutEffect, useMemo, useState } from 'react';
import { Image as BaseImage, ImageBackground } from 'react-native';
import { testID } from '../../../helpers';
import { styles, useBanProps, useBanStylerProps, useDevice, useStyler } from '../../../hooks';
import { getUri } from './helpers';
import { BANNED_PROPS, IMAGE_RESIZE_MODES } from './Image.definition';
const Image = (_a) => {
    var { alt, autoSize = false, resizeMode = IMAGE_RESIZE_MODES.contain, src, srcSet, children } = _a, others = __rest(_a, ["alt", "autoSize", "resizeMode", "src", "srcSet", "children"]);
    const Container = children ? ImageBackground : BaseImage;
    const { uri, sizeUri } = useMemo(() => getUri(src, srcSet), [src, srcSet]);
    const [size, setSize] = useState({ height: 0, width: 0 });
    useLayoutEffect(() => {
        if (autoSize) {
            BaseImage.getSize(sizeUri, (width, height) => height && width && setSize({ height, width }));
        }
    }, [autoSize, others.style, sizeUri]);
    const { height, width } = size;
    return (React.createElement(Container, Object.assign({}, useBanProps(useBanStylerProps(others), BANNED_PROPS), useStyler(Object.assign(Object.assign({}, others), { style: styles(autoSize && height && width ? { height, width, aspectRatio: width / height } : undefined, others.style) }), Image.displayName, useDevice()), testID(others.testID), { resizeMode: resizeMode, accessibilityLabel: alt || others.accessibilityLabel, source: uri ? { uri } : src }), children));
};
Image.displayName = 'Image';
export { Image };
