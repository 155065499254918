// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { isValidDate } from './isValidDate';
import { isValidEmail } from './isValidEmail';
import { isValidPhone } from './isValidPhone';
export const getInputErrors = ({ max = '', min = '', minLength = 0, regexp, required, test, type = 'text', value = '', } = {}) => {
    const errors = {};
    // Common
    if (required && (value === undefined || value.length === 0))
        errors.required = true;
    if (minLength > 0 && value.length < minLength)
        errors.minLength = true;
    if (regexp && regexp.exec && regexp.exec(value) === null)
        errors.regexp = true;
    if (test && !test(value))
        errors.test = true;
    // Email, Phone & Date format
    if ((type === 'date' && !isValidDate(value, { min, max })) ||
        (type === 'email' && !isValidEmail(value)) ||
        (type === 'tel' && !isValidPhone(value))) {
        errors.format = true;
    }
    return Object.keys(errors).length > 0 ? errors : undefined;
};
