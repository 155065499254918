import StyleSheet from 'react-native-extended-stylesheet';
export const style = StyleSheet.create({
    icon: {
        fontFamily: '$iconFamily',
        fontStyle: 'normal',
        fontWeight: 'normal',
        height: '$iconSize',
        width: '$iconSize',
        fontSize: '$iconSize',
    },
    small: {
        fontSize: '$iconSizeSmall',
        height: '$iconSizeSmall',
        width: '$iconSizeSmall',
    },
    large: {
        fontSize: '$iconSizeLarge',
        height: '$iconSizeLarge',
        width: '$iconSizeLarge',
    },
});
