var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { forwardRef } from 'react';
import { Input, Select } from '../../primitives';
import { Date, FieldSet, Password, Phone } from './variants';
export const HOC = forwardRef((_a, ref) => {
    var { multiLine, options, fieldset } = _a, others = __rest(_a, ["multiLine", "options", "fieldset"]);
    const { defaultValue, type, value } = others;
    if (type === 'password')
        return React.createElement(Password, Object.assign({}, others, { ref: ref }));
    else if (type === 'tel')
        return React.createElement(Phone, Object.assign({}, others, { ref: ref }));
    else if (type === 'date')
        return React.createElement(Date, Object.assign({}, others, { color: undefined, ref: ref }));
    else if (options)
        return (React.createElement(Select, Object.assign({}, others, { color: undefined, options: options, value: value || defaultValue, ref: ref })));
    else if (fieldset)
        return React.createElement(FieldSet, Object.assign({}, others, { fields: fieldset, ref: ref }));
    return React.createElement(Input, Object.assign({}, others, { color: undefined, multiline: multiLine, ref: ref }));
});
